import { useState } from "react";
import "./style.css";

const FAQ = () => {
    const [faqState, setFaqState] = useState([false, false, false, false, false, false, false, false, false, false, false, false]);

    const toggleFaq = (index) => {
        setFaqState(faqState.map((isOpen, i) => i === index ? !isOpen : isOpen));
    };

    return (
        <section id="faqs">
            <h2 className="text-center py-5">FAQs</h2>

            <div className="border-t border-t-current" tabIndex="0" role="button" onClick={() => toggleFaq(0)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    What is Tertius AhmaNuph?
                </h3>
                <div className={`prose pb-5 ${faqState[0] ? "d-block" : "d-none"}`}>
                    <p>Tertius AhmaNuph is the official website dedicated to Shaderah Dey-Al’s music, merchandise, and artistic vision. Here, you can explore her work, purchase exclusive items, and stay updated on her latest projects.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="1" role="button" onClick={() => toggleFaq(1)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    How can I purchase music or merchandise?
                </h3>
                <div className={`prose pb-5 ${faqState[1] ? "d-block" : "d-none"}`}>
                    <p>You can purchase music and merchandise directly through our website. Simply browse the shop, select your items, and proceed to checkout. We accept various payment methods for your convenience.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="2" role="button" onClick={() => toggleFaq(2)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    What payment methods do you accept?
                </h3>
                <div className={`prose pb-5 ${faqState[2] ? "d-block" : "d-none"}`}>
                    <p>We accept major credit cards, debit cards, and other secure payment options, which are displayed at checkout. All payments are processed securely through our payment gateway.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="3" role="button" onClick={() => toggleFaq(3)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    How long will it take to receive my order?
                </h3>
                <div className={`prose pb-5 ${faqState[3] ? "d-block" : "d-none"}`}>
                    <p>Shipping times vary depending on your location and the shipping method selected at checkout. You will receive an estimated delivery date during the checkout process. Please note that delays can occur due to unforeseen circumstances.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="4" role="button" onClick={() => toggleFaq(4)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    Can I return or exchange a product?
                </h3>
                <div className={`prose pb-5 ${faqState[4] ? "d-block" : "d-none"}`}>
                    <p>Yes, merchandise can be returned or exchanged within 14 days of receipt if the item is defective or incorrect. Unfortunately, all sales of music (digital or physical) are final and non-refundable. Please refer to our Returns Policy for more details.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="5" role="button" onClick={() => toggleFaq(5)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    How do I track my order?
                </h3>
                <div className={`prose pb-5 ${faqState[5] ? "d-block" : "d-none"}`}>
                    <p>Once your order has shipped, you will receive a confirmation email with a tracking number. You can use this number to monitor your shipment’s progress directly through the carrier’s website.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="6" role="button" onClick={() => toggleFaq(6)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    What should I do if I receive a damaged or incorrect item?
                </h3>
                <div className={`prose pb-5 ${faqState[6] ? "d-block" : "d-none"}`}>
                    <p>If you receive a damaged or incorrect item, please contact us immediately with your order number and a description of the issue. We will work quickly to resolve the problem and ensure you receive the correct product.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="7" role="button" onClick={() => toggleFaq(7)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    Is my personal information secure?
                </h3>
                <div className={`prose pb-5 ${faqState[7] ? "d-block" : "d-none"}`}>
                    <p>Yes, we take your privacy and security seriously. We use industry-standard encryption and security measures to protect your personal information. For more details, please refer to our Privacy Policy.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="8" role="button" onClick={() => toggleFaq(8)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    Can I cancel my order?
                </h3>
                <div className={`prose pb-5 ${faqState[8] ? "d-block" : "d-none"}`}>
                    <p>Orders can only be canceled if they have not yet been processed or shipped. If you need to cancel an order, please contact us as soon as possible with your order number.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="9" role="button" onClick={() => toggleFaq(9)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    How can I contact customer support?
                </h3>
                <div className={`prose pb-5 ${faqState[9] ? "d-block" : "d-none"}`}>
                    <p>If you have any questions or need assistance, you can contact us via the contact form on our website or by emailing us directly at [Your Contact Information]. We aim to respond to all inquiries within 24-48 hours.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="10" role="button" onClick={() => toggleFaq(10)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    Do you offer international shipping?
                </h3>
                <div className={`prose pb-5 ${faqState[10] ? "d-block" : "d-none"}`}>
                    <p>Yes, we offer international shipping to various locations. Shipping costs and delivery times will be calculated at checkout based on your location.</p>
                </div>
            </div>

            <div className="border-t border-t-current" tabIndex="11" role="button" onClick={() => toggleFaq(11)}>
                <h3 className="font-bold text-heading py-6 pl-6 pr-20 w-full duration-150 flex items-center justify-between relative after:rounded-full after:bg-current after:w-3 after:h-3 after:block after:absolute after:right-6 opacity-1 text-lg">
                    How can I stay updated on new releases and merchandise?
                </h3>
                <div className={`prose pb-5 ${faqState[11] ? "d-block" : "d-none"}`}>
                    <p>You can stay updated by signing up for our newsletter, following us on social media, or regularly checking our website for the latest news and releases.</p>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
