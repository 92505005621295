import React, { useEffect, useState } from "react";
import roomMediun from "./img/room-2560.jpg";
import roomSmall from "./img/room-1920.jpg";
import roomXs from "./img/room-1024.jpg";
import room from "./img/room-2.jpg";
import { NavLink } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './style.css';

const Room = () => {
    const [wrapperWidth, setWrapperWidth] = useState(window.innerWidth);

    const adjustWrapperWidth = () => {
        const aspectRatio = 16 / 9;
        const viewportWidth = window.innerWidth;

        if (viewportWidth <= 1100) {
            setWrapperWidth(1120);
            document.getElementById("room").style.height = `687px`;
        } else {
            const newHeight = viewportWidth / aspectRatio;
            setWrapperWidth(viewportWidth);
            document.getElementById("room").style.height = `${newHeight}px`;
        }
    };

    const tooltips = {
        dotOne: "3am on 31st",
        dotTwo: "Get This Way",
        dotThree: "Kneedle In My Back Interlude",
        dotFour: "Printed T Shirt Black",
        dotFive: "Printed T Shirt Brown",
        dotSix: "Printed T Shirt White"
    };

    const renderTooltip = (content) => (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {content}
        </Tooltip>
    );

    useGSAP(() => {
        const tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });
        tl.to('.dot', {
            scale: 1.2,
            duration: 1,
            ease: 'elastic.out(1,0.3)',
            yoyo: true
        }, "cool kids")

        tl.to(".ring", {
            opacity: 1,
            scale: 1,
            ease: 'easeInOut',
            duration: 1.5
        }, "cool kids")

        tl.to(".ring", {
            scale: 1.5,
            opacity: 0,
            ease: 'easeInOut',
            duration: 1.5,
        }, "cool kids+=0.4");
    })

    useEffect(() => {
        adjustWrapperWidth();
        window.addEventListener("resize", adjustWrapperWidth);

        return () => {
            window.removeEventListener("resize", adjustWrapperWidth);
        };
    }, []);

    return (
        <section>
            <div className="room-wrapper h-screen h-screen-ios">
                <div className="relative min-w-full min-h-full overflow-hidden" id="room" style={{ width: `${wrapperWidth <= 1100 ? 1099.2 : wrapperWidth}px`, height: `${wrapperWidth <= 1100 ? '687px' : wrapperWidth / (16 / 9)}px` }}>
                    <img alt="" src={roomSmall} className="w-full h-full absolute top-0 left-0 pointer-events-none blur-xl transition-opacity duration-200 ease-in-out z-10 opacity-0" />

                    <picture style={{ direction: "rtl" }}>
                        <source media="(min-width: 1240px)" srcSet={room} />
                        <source media="(min-height: 600px)" srcSet={roomMediun} />
                        <source media="(min-height: 500px)" srcSet={roomSmall} />
                        <img className="w-full pointer-events-none" src={roomXs} alt="" />
                    </picture>
                    <div className="absolute top-0 left-0 w-full h-full">

                        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.dotOne)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-one" to={"/products/7334983270477"} data-discover="true">
                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>
                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>
                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    3am on 31st
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.dotTwo)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-two" to={"/products/7334983434317"} data-discover="true">
                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>
                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>
                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    Get This Way
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" overlay={renderTooltip(tooltips.dotThree)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-three" to={"/products/7334983467085"} data-discover="true">
                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>
                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>
                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    Kneedle In My Back Interlude
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.dotFour)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-four" to={"/products/7333016502349"} data-discover="true">
                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>
                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>
                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    Printed T Shirt Black
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.dotFive)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-five" to={"/products/7334979469389"} data-discover="true">

                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>

                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>

                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    Printed T Shirt Brown
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                        <OverlayTrigger placement="top" overlay={renderTooltip(tooltips.dotSix)}>
                            <NavLink className="d-block z-30 w-[11px] h-[11px] position-absolute dot-six" to={"/products/7334979469389"} data-discover="true">

                                <span className="d-block p-4 md:p-0 absolute -top-4 -left-4 md:static before:block before:bg-white before:rounded-full before:w-[11px] before:h-[11px] before:shadow-[0_0_10px_5px_#00000040] dot"></span>

                                <span className="d-block absolute bg-white/5 border border-white rounded-full pointer-events-none -top-[40px] -bottom-[40px] -left-[40px] -right-[40px] ring"></span>

                                <div className="transition duration-150 ease-in-out none absolute top-full bg-white font-bold text-base py-2 px-4 rounded-full left-1/2 -translate-x-1/2 opacity-1 pointer-events-auto" style={{ whiteSpace: "nowrap", lineHeight: "1", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", transform: "translateX(-50%) translateY(0px)" }}>
                                    <span className="d-block absolute -top-1 left-1/2 -translate-x-1/2 z-10 rotate-45 w-2 h-2 bg-white rounded-[2px_0_0] shadow-[0_0_10px_#0000001a]"></span>
                                    Printed T Shirt White
                                </div>
                            </NavLink>
                        </OverlayTrigger>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Room;
