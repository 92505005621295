import "./style.css";
import { Container, Row, Col } from "react-bootstrap";

const TAndC = () => {
  return (
    <>
      <main className="terms">
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-center">Terms of Service</h1>
              <br />
              <br />

              <h2>Welcome to Tertius AhmaNuph</h2>

              <p>
                These Terms and Conditions outline the rules and regulations for the use of Tertius AhmaNuph’s website. By accessing or using our website, you agree to be bound by these Terms. Please read them carefully before making any purchase or using our services.
              </p>

              <h2>1. General Information</h2>
              <p><strong>Website Ownership:</strong> Tertius AhmaNuph is the official platform for Shaderah Dey-Al’s music, merchandise, and related content. All content on this website, including music, images, and text, is the intellectual property of Shaderah Dey-Al and Tertius AhmaNuph.</p>
              <p><strong>Modification of Terms:</strong> We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of the website following any changes signifies your acceptance of the new Terms.</p>

              <h2>2. Use of the Website</h2>
              <p><strong>Eligibility:</strong> By using this website, you confirm that you are at least 18 years old or have the consent of a parent or guardian to use the site and make purchases.</p>
              <p><strong>Account Security:</strong> If you create an account on our website, you are responsible for maintaining the confidentiality of your login details. Tertius AhmaNuph will not be liable for any loss or damage arising from your failure to protect your account information.</p>

              <h2>3. Purchases and Payments</h2>
              <p><strong>Product Availability:</strong> All merchandise and music are subject to availability. We strive to keep our inventory updated, but in rare cases, an item may become unavailable after your order is placed. If this happens, we will notify you and offer a refund or alternative product.</p>
              <p><strong>Pricing:</strong> Prices for products are displayed on the website and are subject to change without notice. The price at the time of purchase is the final price.</p>
              <p><strong>Payment:</strong> We accept various payment methods, which are listed during the checkout process. By making a purchase, you agree to provide current, complete, and accurate payment information.</p>

              <h2>4. Shipping and Delivery</h2>
              <p><strong>Shipping:</strong> We ship to various locations as specified during checkout. Shipping fees and delivery times vary based on your location and the shipping method selected.</p>
              <p><strong>Delivery:</strong> Estimated delivery times are provided for your convenience but are not guaranteed. Delays may occur due to unforeseen circumstances, and Tertius AhmaNuph is not liable for such delays.</p>

              <h2>5. Returns and Refunds</h2>
              <p><strong>Return Policy:</strong> Due to the nature of our products, all sales of music (digital or physical) are final and non-refundable. Merchandise can be returned or exchanged within 14 days of receipt if the item is defective or incorrect. Items must be returned in their original condition and packaging.</p>
              <p><strong>Refunds:</strong> Refunds for returned merchandise will be processed within 7-10 business days of receiving the item. Shipping costs are non-refundable unless the return is due to our error.</p>

              <h2>6. Intellectual Property</h2>
              <p><strong>Copyright:</strong> All content on this website, including but not limited to music, videos, images, text, and design, is protected by copyright laws. Unauthorized use or reproduction of any content is strictly prohibited.</p>
              <p><strong>Trademarks:</strong> The trademarks, logos, and service marks displayed on the website are the property of Shaderah Dey-Al and Tertius AhmaNuph. Use of these marks without our permission is prohibited.</p>

              <h2>7. Privacy Policy</h2>
              <p><strong>Data Collection:</strong> We collect personal information that you provide to us during account creation, purchase, or contact. This information is used to process your orders and improve your experience on our website.</p>
              <p><strong>Data Protection:</strong> We take your privacy seriously and employ security measures to protect your personal information. Please refer to our Privacy Policy for more detailed information on how we handle your data.</p>

              <h2>8. Limitation of Liability</h2>
              <p><strong>No Warranties:</strong> Tertius AhmaNuph provides the website and its contents on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the operation of the website or the information, content, materials, or products included on it.</p>
              <p><strong>Liability:</strong> Tertius AhmaNuph will not be liable for any damages arising from the use or inability to use the website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

              <h2>9. Governing Law</h2>
              <p>These Terms and Conditions are governed by and construed in accordance with the laws of USA, without regard to its conflict of law principles. Any disputes arising under or related to these Terms will be resolved in the courts of USA.</p>

              <h2>10. Contact Information</h2>
              <p>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:tertiusdim9nsion@gmail.com" className="text-black">tertiusdim9nsion@gmail.com</a>.</p>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default TAndC;
