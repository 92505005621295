import "./header.css";
import logo from "../../logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { CART_UPDATED_EVENT } from '../CartEvent/CartEvent.js';
import axios from "axios";

const Header = ({ toggleCart, toggleAlbums }) => {
  const [active, setActive] = useState('room');
  const [isHeader, setIsHeader] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const { pathname } = useLocation();
  const mobMainRef = useRef(null);

  const handleClick = (menuItem) => {
    setActive(menuItem);
  };

  const fetchCartDetails = async () => {
    let cartId = JSON.parse(localStorage.getItem('shopify_cart_id'));

    if (cartId) {
      try {
        const fetchCartResponse = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `
              query($cartId: ID!) {
                cart(id: $cartId) {
                  lines(first: 10) {
                    edges {
                      node {
                        quantity
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              cartId: cartId
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );

        const cartLines = fetchCartResponse.data.data.cart.lines.edges;

        const newTotalQuantity = cartLines.reduce((total, line) => {
          return total + line.node.quantity;
        }, 0);

        setTotalQuantity(newTotalQuantity);
      } catch (error) {
        console.error("Error fetching cart details:", error);
      }
    }
  };

  useEffect(() => {
    fetchCartDetails();

    window.addEventListener(CART_UPDATED_EVENT, fetchCartDetails);

    return () => {
      window.removeEventListener(CART_UPDATED_EVENT, fetchCartDetails);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        mobMainRef.current &&
        !mobMainRef.current.contains(event.target) &&
        !event.target.closest(".hamburger")
      ) {
        setIsHeader(false);
      }
    }

    if (isHeader) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHeader]);

  useEffect(() => {
    // Close the sidebar when the user navigates to a new page
    setIsHeader(false);
  }, [pathname]);

  const handleHamburgerClick = () => {
    setIsHeader((prev) => !prev);
  };

  return (
    <>
      <header
        id="main-menu"
        className={`d-flex align-items-center justify-content-between ${pathname === "/room" ? "room__header" : ""}`}>
        <nav>
          <ul className="d-flex align-items-center">
            <li>
              <NavLink to={`/room`}>
                <img src={logo} id="logo" alt="logo" loading="lazy" />
              </NavLink>
            </li>
            <li className={active === 'home' ? 'active' : ''} onClick={() => handleClick('home')}>
              <NavLink to={`/home`}>Home</NavLink>
            </li>
            <li className={active === 'about' ? 'active' : ''} onClick={() => handleClick('about')}>
              <NavLink to={`/about`}>About</NavLink>
            </li>
            <li>
              <button onClick={toggleAlbums} className={active === 'albums' ? 'active' : ''}>Albums</button>
            </li>
            <li className={pathname === '/gallery' ? 'active' : ''}>
              {/* <NavLink to={`/gallery`}>Gallery</NavLink> */}
              <a href="/gallery">Gallery</a>
            </li>
            <li className={active === 'youtube' ? 'active' : ''} onClick={() => handleClick('youtube')}>
              <NavLink to={`/youtube`}>YouTube</NavLink>
            </li>
            <li className={active === 'contact' ? 'active' : ''} onClick={() => handleClick('contact')}>
              <NavLink to={`/contact`}>Contact</NavLink>
            </li>
            <li className={active === 'shop-all' ? 'active' : ''} onClick={() => handleClick('shop-all')}>
              <NavLink to={`/shop-all`}>Shop All</NavLink>
            </li>
          </ul>
        </nav>
        <button
          className={pathname === "/shop-all" || /products\/.*$/.test(pathname) ? "d-block" : "d-none"}
          onClick={toggleCart}>
          Cart ( {totalQuantity} )
        </button>
      </header>

      <header id="mob-menu">
        <div
          className={`d-flex align-items-center justify-content-between ${pathname === "/room" ? "room__header" : ""}`}>
          <div>
            <NavLink to={`/room`}>
              <img src={logo} id="logo" alt="logo" width={150} loading="lazy" />
            </NavLink>
          </div>
          <button className="hamburger" onClick={handleHamburgerClick}>
            |||
          </button>
        </div>
      </header>

      <nav
        ref={mobMainRef}
        id="mob-main"
        className={`${isHeader ? "left-0" : "left-full"} ${pathname === "/room" ? "transparent room__header" : ""}`}>
        <ul className="d-flex flex-column align-items-left">
          <li>
            <NavLink to={`/home`}>Home</NavLink>
          </li>
          <li>
            <div onClick={toggleAlbums}>Albums</div>
          </li>
          <li>
            <NavLink to={`/about`}>About</NavLink>
          </li>
          <li>
            <NavLink to={`/gallery`}>Gallery</NavLink>
          </li>
          <li>
            <NavLink to={`/youtube`}>YouTbe</NavLink>
          </li>
          <li>
            <NavLink to={`/contact`}>Contact</NavLink>
          </li>
          <li>
            <NavLink to={`/shop-all`}>Shop All</NavLink>
          </li>
          <li>
            <div
              className="d-block"
              onClick={() => {
                toggleCart();
                setIsHeader(false);
              }}>
              Cart ( {totalQuantity} )
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
