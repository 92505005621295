import { Container, Row } from "react-bootstrap";
import ProductCard from "../../ProductCard";
import { useEffect, useState } from "react";
import axios from "axios";
import "./shop.css";

const Shop = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              products(first: 10) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 4) {
                      edges {
                        node {
                          src
                          altText
                        }
                      }
                    }
                    variants(first: 1) { 
                      edges { 
                        node { 
                          priceV2 { 
                            amount 
                          } 
                        } 
                      } 
                    }
                  }
                }
              }
            }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );
        setProducts(response.data.data.products.edges);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const extractId = (gid) => gid.split("/").pop();

  return (
    <>
      <main id="shop">
        <h1>Shop All</h1>

        <Container fluid>
          <Row>
            {products.map((product) => {
              const images = product.node.images.edges.map((img) => img.node.src);

              return (
                <ProductCard
                  key={extractId(product.node.id)}
                  productUrl={`/products/${extractId(product.node.id)}`}
                  title={product.node.title}
                  price={"$" + product.node.variants.edges[0]?.node.priceV2.amount}
                  image={images}
                />
              );
            })}
          </Row>
        </Container>
      </main>
    </>
  );
};

export default Shop;
