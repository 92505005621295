import { dispatchCartUpdatedEvent } from '../../CartEvent/CartEvent.js';
import arrowRight from "../../../assets/img/arrow-right-R5M4PHG7.svg";
import arrowLeft from "../../../assets/img/arrow-left-3PGR7TIV.svg";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../../ProductCard";
import axios from "axios";
import "./products.css";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const { id } = useParams();

  const [cursorStyle, setCursorStyle] = useState("pointer");
  const [showSizes, setShowSizes] = useState(false);
  const [sizes, setSizes] = useState("S");
  const [isAlbum, setIsAlbum] = useState(false);
  const [isCartAdded, setIsCartAdded] = useState(false);
  const [sizeGuide, setSizeGuide] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              products(first: 6) {
                edges {
                  node {
                    id
                    title
                    description
                    images(first: 4) {
                      edges {
                        node {
                          src
                          altText
                        }
                      }
                    }
                    variants(first: 4) { 
                      edges { 
                        node { 
                          priceV2 { 
                            amount 
                          }
                          selectedOptions {
                            name
                            value
                          } 
                        } 
                      } 
                    }
                  }
                }
              }
            }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );
        setProducts(response.data.data.products.edges);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchProductById = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              product(id: "gid://shopify/Product/${id}") {
                id
                title
                description
                images(first: 4) {
                  edges {
                    node {
                      src
                      altText
                    }
                  }
                }
                variants(first: 4) {
                  edges {
                    node {
                      id
                      priceV2 {
                        amount
                      }
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                collections(first: 2) {
                  edges {
                    node {
                      title
                    }
                  }
                }
              }
            }`

          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );
        // console.log(response.data.data.product);
        setProduct(response.data.data.product);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProductById();
  }, [id]);

  useEffect(() => {
    if (product && product.collections.edges.length > 0) {
      const isInAlbumsCollection = product.collections.edges.some(
        (collection) => collection.node.title === "Albums"
      );
      setIsAlbum(isInAlbumsCollection);
    }
  }, [product]);

  if (!product) return <div>Loading...</div>;

  const extractId = (gid) => gid.split("/").pop();
  const relatedProducts = products.filter(
    (item) => extractId(item.node.id) !== id
  );

  const handleMouseMove = (e) => {
    const imageRect = imageRef.current.getBoundingClientRect();
    const imageWidth = imageRect.width;
    const mouseX = e.clientX - imageRect.left;

    if (mouseX < imageWidth / 4) {
      setCursorStyle(`url(${arrowLeft}) 15 8, e-resize`);
    } else if (mouseX > (3 * imageWidth) / 4) {
      setCursorStyle(`url(${arrowRight}) 15 8, e-resize`);
    } else {
      setCursorStyle("pointer");
    }
  };

  const handleMouseLeave = () => {
    setCursorStyle("pointer");
  };

  const handleImageClick = () => {
    if (cursorStyle !== "pointer") {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.edges.length);
    }
  };

  const handleAddToCart = async (productToAdd, quantity = 1) => {
    let cartId = JSON.parse(localStorage.getItem('shopify_cart_id'));
    setIsCartAdded(true);

    setTimeout(() => {
      setIsCartAdded(false);
    }, 2000);

    try {
      let cartLines;

      if (cartId) {
        const fetchCartResponse = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `
              query($cartId: ID!) {
                cart(id: $cartId) {
                  lines(first: 10) {
                    edges {
                      node {
                        id
                        quantity
                        merchandise {
                          ... on ProductVariant {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              cartId: cartId
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );

        const existingLines = fetchCartResponse.data.data.cart.lines.edges;
        const existingLine = existingLines.find(line => line.node.merchandise.id === productToAdd.variants.edges[0].node.id);

        if (existingLine) {
          cartLines = [
            {
              id: existingLine.node.id,
              quantity: existingLine.node.quantity + quantity
            }
          ];

          const updateCartResponse = await axios.post(
            `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
            {
              query: `
                mutation($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                  cartLinesUpdate(cartId: $cartId, lines: $lines) {
                    cart {
                      id
                      lines(first: 10) {
                        edges {
                          node {
                            id
                            quantity
                            merchandise {
                              ... on ProductVariant {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `,
              variables: {
                cartId: cartId,
                lines: cartLines
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
              },
            }
          );
          console.log(updateCartResponse.data);
        }
        else {
          cartLines = [
            {
              merchandiseId: productToAdd.variants.edges[0].node.id,
              quantity: quantity
            }
          ];

          const addCartResponse = await axios.post(
            `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
            {
              query: `
                mutation($cartId: ID!, $lines: [CartLineInput!]!) {
                  cartLinesAdd(cartId: $cartId, lines: $lines) {
                    cart {
                      id
                      lines(first: 10) {
                        edges {
                          node {
                            id
                            quantity
                            merchandise {
                              ... on ProductVariant {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `,
              variables: {
                cartId: cartId,
                lines: cartLines
              }
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
              },
            }
          );

          console.log(addCartResponse.data);
        }

      } else {
        const createCartResponse = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `
              mutation($input: CartInput!) {
                cartCreate(input: $input) {
                  cart {
                    id
                    lines(first: 10) {
                      edges {
                        node {
                          id
                          quantity
                          merchandise {
                            ... on ProductVariant {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              input: {
                lines: [{
                  quantity: quantity,
                  merchandiseId: productToAdd.variants.edges[0].node.id
                }],
                attributes: [
                  {
                    key: "cart_attribute",
                    value: "This is a cart attribute"
                  }
                ]
              }
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            },
          }
        );

        const { cart } = createCartResponse.data.data.cartCreate;

        if (cart) {
          cartId = cart.id;
          localStorage.setItem('shopify_cart_id', JSON.stringify(cartId));
          window.dispatchEvent(new Event("storage"));
        } else {
          console.error('Error creating cart:', createCartResponse.data);
        }
      }

    } catch (error) {
      console.error('Error handling cart addition:', error);
    }
    dispatchCartUpdatedEvent();
  };


  if (!product) return <div>Loading...</div>;

  return (
    <>
      <Container fluid className="product-detail">
        <Row>
          <Col xs="12" sm="12" md="6" xl="6">
            <figure>
              <img
                src={product.images.edges[currentImageIndex].node.src}
                ref={imageRef}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                onClick={handleImageClick}
                style={{ cursor: cursorStyle }}
                className="product-img"
                alt={product.images.edges[currentImageIndex].node.altText}
              />
            </figure>
          </Col>

          <Col xs="12" sm="12" md="6" xl="6">
            <section className="d-flex flex-column justify-content-between h-100">
              <div
                className={`w-100 d-flex align-items-center justify-content-center antialiased added-to-cart ${isCartAdded ? "translate-0" : "translate-full"
                  }`}>
                Product added to cart
              </div>

              <div className="head">
                <h1 className="product-title">{product.title}</h1>
                <h2 className="product-price">{"$" + product.variants.edges[0].node.priceV2.amount}</h2>
              </div>

              <div className="foot">
                <p className="product-description w-50">{product.description}</p>

                <button
                  className={`size-guide ${isAlbum ? "d-none" : ""}`}
                  onClick={() => setSizeGuide(true)}>
                  Size Guide
                </button>

                <div className={`z-50 position-relative ${sizeGuide ? "d-flex" : "d-none"}`}
                  onClick={() => setSizeGuide(false)}>
                  <div className="position-fixed w-100 z-50 modal-bg">
                    <div className="d-flex align-items-center justify-content-center text-center modal-inner">
                      <div className="position-relative modal-in">
                        <div
                          className="position-absolute modal-inn"
                          onClick={() => setSizeGuide(false)}>
                          &times;
                        </div>
                        <div className="d-flex justify-content-between text-xs">
                          <h4 className="sz-color">Size Guide</h4>
                          <div className="sz-color">{product.title}</div>
                        </div>

                        <div className="sizeguide-table">
                          <table className="table border border-light">
                            <tbody className="table-body">
                              <tr>
                                <th>
                                  <p>SIZE</p>
                                </th>
                                <th>
                                  <p>Width(in)</p>
                                </th>
                                <th>
                                  <p>Length(in)</p>
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <p>S</p>
                                </td>
                                <td>
                                  <p>25.5</p>
                                </td>
                                <td>
                                  <p>27.5</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>M</p>
                                </td>
                                <td>
                                  <p>27</p>
                                </td>
                                <td>
                                  <p>28</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>L</p>
                                </td>
                                <td>
                                  <p>28</p>
                                </td>
                                <td>
                                  <p>28.75</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>XL</p>
                                </td>
                                <td>
                                  <p>30.5</p>
                                </td>
                                <td>
                                  <p>29.5</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>XXL</p>
                                </td>
                                <td>
                                  <p>32.5</p>
                                </td>
                                <td>
                                  <p>30.5</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Size" className="position-relative">
                  <button
                    type="button"
                    className={`btn-size text-center w-100 ${isAlbum ? "d-none" : ""}`}
                    onClick={() => setShowSizes(!showSizes)}>
                    Size: {sizes}
                  </button>

                  <div
                    className={`position-absolute w-100 border border-light size-wrapper ${showSizes ? "d-grid" : "d-none"
                      }`}
                    id="sizes">
                    <button
                      type="button"
                      onClick={() => {
                        setSizes("S");
                        setShowSizes(false);
                      }}
                      className="w-100 position-relative border border-light border-start-0 border-top-0 all-sizes">
                      S
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSizes("M");
                        setShowSizes(false);
                      }}
                      className="w-100 position-relative border border-light border-top-0 border-start-0 border-end-0 all-sizes">
                      M
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSizes("L");
                        setShowSizes(false);
                      }}
                      className="w-100 border border-light border-top-0 border-start-0 position-relative all-sizes">
                      L
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSizes("XL");
                        setShowSizes(false);
                      }}
                      className="w-100 border border-light border-top-0 border-start-0 border-end-0 position-relative all-sizes">
                      XL
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSizes("XXL");
                        setShowSizes(false);
                      }}
                      className="w-100 border border-light border-top-0 border-start-0 border-bottom-0 position-relative all-sizes">
                      XXL
                    </button>
                  </div>

                  <button
                    type="button"
                    onClick={() => handleAddToCart(product, 1)}
                    className="btn-add-to-cart text-center w-100">
                    Add To Cart
                  </button>
                </div>
              </div>
            </section>
          </Col>

          <h2 className="text-center related-products">Related Products</h2>

          {relatedProducts.map((relatedProduct) => (
            <ProductCard
              key={relatedProduct.node.id}
              productUrl={`/products/${extractId(relatedProduct.node.id)}`}
              title={relatedProduct.node.title}
              price={"$" + relatedProduct.node.variants.edges[0].node.priceV2.amount}
              image={relatedProduct.node.images.edges.map((img) => img.node.src)}
            />
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Products;
