import "./style.css";
import { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import imageOne from "../../../assets/img/image10.jpeg";
import { useGSAP } from "@gsap/react";
import { Expo, Power1, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger, useGSAP);

const About = () => {
  let aboutMe = useRef(null);
  let content = useRef(null);
  let aboutSec = useRef(null);
  let bio = useRef(null);

  useGSAP(() => {
    SplitType.create(aboutMe.current);
    SplitType.create(bio.current.children[0], { types: "lines, words" });
    SplitType.create(bio.current.children[1], { types: "lines, words" });
    SplitType.create(bio.current.children[2], { types: "lines, words" });
    SplitType.create(bio.current.children[3], { types: "lines, words" });
    SplitType.create(bio.current.children[4], { types: "lines, words" });
    SplitType.create(bio.current.children[5], { types: "lines, words" });

    const tl = gsap.timeline();

    tl.set(".about-inner .line", {
      overflow: "hidden",
    });

    tl.set(".about-blob .char", {
      yPercent: 100,
    });

    tl.set(".about-blob .line", {
      overflow: "hidden",
    });

    tl.to(
      ".about-blob .char",
      {
        yPercent: 0,
        stagger: 0.05,
        duration: 0.5,
        delay: 0.5,
      },
      "cool kids"
    );

    tl.fromTo(".about-img", { clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)" }, {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: Power1.out,
      duration: 2,
    }).to(".about-img", {
      scale: 1,
      delay: -2,
      duration: 1.4,
      ease: Power1.out
    });

    tl.from(
      ".about-inner .word",
      {
        duration: 1.5,
        yPercent: 130,
        stagger: 0.01,
        ease: Expo.easeInOut,
      },
      "cool kids"
    );
  }, []);

  return (
    <>
      <section id="about" className="py-5" ref={aboutSec}>
        <Container fluid>
          <div className="about-blob-container">
            <h2 className="about-blob" ref={aboutMe}>
              Welcome to Tertius AhmaNuph
            </h2>
          </div>
          <Row className="p-3">

            <Col xs="12" sm="12" md="12" lg="6" xl="6"
              className="about-text d-flex flex-column align-items-center" ref={content}>
              <div className="about-inner" ref={bio}>
                <p>
                  Tertius AhmaNuph is more than just a website; it’s the gateway to the world of Shaderah Dey-Al—an artist, storyteller, and visionary who has crafted a unique music label that blends powerful melodies with a distinct artistic identity. This platform is your destination to explore Shaderah’s world, where music, art, and community seamlessly converge.
                </p>

                <p>
                  Our mission at Tertius AhmaNuph is rooted in authenticity and connection. Shaderah Dey-Al is dedicated to creating music that resonates deeply with her audience, building a community where listeners can find inspiration and solace, and offering merchandise that mirrors her artistic vision. We aim to provide a holistic experience, from the moment you press play on a track to when you wear our exclusive merchandise.
                </p>

                <p>
                  "Lonely Homes," Shaderah’s forthcoming album, is not just a collection of songs—it’s a journey through the emotional landscapes of life, love, and solitude. Each single from this album serves as a glimpse into the overall experience Shaderah wishes to share with her audience. The music is available exclusively through distribution links provided on Tertius AhmaNuph, ensuring that each purchase directly supports the artist.
                </p>

                <p>
                  Our merchandise line is a natural extension of Shaderah Dey-Al’s artistry. Each item, from clothing to accessories, is crafted to reflect the themes and aesthetics that define Shaderah’s work. All merchandise is available exclusively through Tertius AhmaNuph, offering fans a tangible connection to Shaderah’s creative world.
                </p>

                <p>
                  At Tertius AhmaNuph, we believe in the unifying power of music. Shaderah Dey-Al’s work is not just about personal expression but about fostering a community where fans can connect, share their experiences, and support one another. Through our platform, we strive to create a space where this community can flourish, united by a shared love for Shaderah’s art.
                </p>

                <p>
                  For our most dedicated fans, Tertius AhmaNuph offers exclusive experiences that deepen the connection with Shaderah’s work. From limited edition releases to behind-the-scenes content and special events, being a fan of Shaderah Dey-Al is more than just enjoying her music—it’s about being part of something bigger.
                </p>
              </div>
            </Col>

            <Col xs="12" sm="12" md="12" lg="12" xl="6">
              <div className="img-wrapper">
                <img src={imageOne} alt="about shaderah" className="about-img" />
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
