import "./home.css";
import { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import albumOne from "../../../assets/img/album_1.webp";
import heroVedio from "../../../assets/img/hero-vedio.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import SplitType from "split-type";
import ScrollTrigger from "gsap/ScrollTrigger";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {

  const [album, setAlbum] = useState([]);
  const [activeTab, setActiveTab] = useState("story");

  let albums = useRef(null);
  let herotxt = useRef(null);
  let spanTxt = useRef(null);
  let videoRef = useRef(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useGSAP(() => {
    SplitType.create(herotxt.current);
    SplitType.create(spanTxt.current);
    SplitType.create(albums.current);

    gsap.from("#hero-section .char", {
      yPercent: 100,
      delay: 1,
      duration: 0.5,
      stagger: 0.05,
    });

    gsap.from("#albums .char", {
      scrollTrigger: {
        trigger: "#albums",
        start: "top 80%",
      },
      yPercent: 100,
      delay: 1,
      duration: 0.5,
      stagger: 0.05,
    });
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error trying to play video:", error);
      });
    }
  }, []);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const response = await axios.post(
          `https://1cd11f-e1.myshopify.com/api/2023-07/graphql.json`,
          {
            query: `{
              collectionByHandle(handle: "albums") {
                products(first: 3) {
                  edges {
                    node {
                      id
                      title
                      description
                      images(first: 1) {
                        edges {
                          node {
                            src
                            altText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': 'fcfc23a617e5f957bd56a60f1b68191b',
            }
          }
        );
        setAlbum(response.data.data.collectionByHandle.products.edges);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchAlbums();
  }, []);


  const extractId = (gid) => gid.split("/").pop();

  return (
    <>
      <main id="home">
        <section id="hero-section" className="banner">
          <video
            preload="meta"
            ref={videoRef}
            src={heroVedio}
            muted
            autoPlay
            loop
            className="hero-video"
          ></video>
        </section>

        <section id="albums" className="pt-5">
          <div className="pt-5">
            <div className="album-txt-wrapper">
              <h2 className="text-center" ref={albums}>
                Albums
              </h2>
            </div>

            <p className="text-center font">
              Single and all music albums released
            </p>

            <Container fluid>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                  <div className="wvc-column-container wvc-column-bg-default wvc-column-bg-effect-none wvc-font-inherit wvc-column-font-inherit wvc-column-style-none wvc-border-color-none wvc-background-color-default">
                    <div className="wvc-column-inner">
                      <div className="wvc-column-wrapper wpb_wrapper">
                        <div className="wvc-album-disc wvc-album-disc-align-left wvc-album-disc-cd wvc-album-disc-worn-border-yes wvc-album-disc-rotate-hover-stop wvc-element wvc-album-disc-has-link">
                          <span className="wvc-album-disc-link-mask internal-link"></span>
                          <div className="wvc-album-disc-cover-container">
                            <div
                              className="wvc-album-disc-disc-container wow wvc-album-disc-reveal animated"
                              style={{
                                transitionDelay: "0.4s",
                                visibility: "visible",
                              }}>
                              <div
                                className="wvc-album-disc-disc-inner"
                                style={{ animationDuration: "3.5s" }}>
                                <img
                                  decoding="async"
                                  className="wvc-album-disc-disc-img"
                                  src={albumOne}
                                  alt="album"
                                  loading="lazy"
                                />
                              </div>
                              <div className="wvc-album-disc-disc-hole"></div>
                            </div>
                          </div>
                          <div className="wvc-album-disc-cover-inner">
                            <img
                              decoding="async"
                              className="wvc-album-disc-cover-img"
                              src={albumOne}
                              alt="cd-cover"
                              title="cd-cover"
                              loading="lazy"
                            />
                            <div className="wvc-album-disc-cover-border"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="wvc-text-block wvc-clearfix wvc-element wvc-mobile-text-align- wvc-wow fadeIn"
                        style={{
                          animationDelay: "0.2s",
                          visibility: "visible",
                          animationName: "fadeIn",
                        }}
                      ></div>
                    </div>
                  </div>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="6"
                  style={{ marginTop: "1.5rem" }}>
                  <div className="head">
                    <ul className="d-flex flex-wrap align-items-center">
                      <li
                        className={`story ${activeTab === "story" ? "active" : ""
                          }`}
                        onClick={() => handleTabChange("story")}>
                        The Story
                      </li>
                      <li
                        className={`concept ${activeTab === "concept" ? "active" : ""
                          }`}
                        onClick={() => handleTabChange("concept")}>
                        The Concept
                      </li>
                    </ul>

                    <div className={activeTab === "story" ? "" : "d-none"} style={{ height: "357px", overflow: "auto" }}>
                      <ol>
                        <li>
                          <span className="size">Shaderah’s Artistic Journey</span> <br />
                          Shaderah Dey-Al’s path to music has been one of passion and determination, leading her to create her own music label. Her journey reflects a deep commitment to crafting music that resonates with listeners on a personal level.
                        </li>
                        <br />
                        <li>
                          <span className="size">Building a Musical Community</span> <br />
                          Shaderah’s music is more than just sound; it’s about fostering a community of fans who connect through shared experiences and the powerful emotions in her work.
                        </li>
                        <br />
                        <li>
                          <span className="size">The "Lonely Homes" Album</span> <br />
                          "Lonely Homes" is a heartfelt collection of songs that explore life’s complexities, blending Shaderah’s personal experiences with universal themes of love and solitude.
                        </li>
                      </ol>
                    </div>

                    <div className={activeTab === "concept" ? "" : "d-none"} style={{ height: "357px", overflow: "auto" }}>
                      <ol>
                        <li>
                          <span className="size">Exclusive Fan Experiences</span> <br />
                          Shaderah offers exclusive experiences for her dedicated fans, from limited edition items to special content, deepening the connection between the artist and her audience.
                        </li>
                        <br />
                        <li>
                          <span className="size">Art Meets Merchandise</span> <br />
                          Shaderah’s merchandise line is an extension of her artistic vision, designed to complement her music and allow fans to carry a piece of her world with them.
                        </li>
                        <br />
                        <li>
                          <span className="size">Kneedle In My Back Interlude</span> <br />
                          While dealing with medical issues the song came about due to how everyone around her was treating the situation & emotional stress that came from being injured physically & mentally. The need for Shaderah to take some space for herself & regain some dignity while accepting responsibility for her own part within the situation.
                        </li>
                        <li>
                          <span className="size">Exploring "Lonely Homes"</span> <br />
                          The album "Lonely Homes" delves into themes of solitude and connection, offering a poignant reflection on the duality of feeling alone amidst others.
                        </li>
                      </ol>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="d-flex align-items-center justify-content-center position-relative my-5">
              {album.length >= 3 ? (
                <Swiper
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={100}
                  loop={true}
                  slidesPerView={"auto"}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  pagination={{ clickable: true }}
                  // autoplay={{
                  //   delay: 5000,
                  //   disableOnInteraction: false,
                  // }}
                  modules={[EffectCoverflow, Pagination, Autoplay]}
                  className="mySwiper">
                  {album.map((item) => (
                    <SwiperSlide key={extractId(item.node.id)}>
                      <NavLink to={`/products/${extractId(item.node.id)}`}>
                        <img src={item.node.images.edges[0].node.src} alt={item.node.images.edges[0].node.altText || 'Product Image'} loading="lazy" />
                      </NavLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
